import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {environment} from "../../../../../environments/environment";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DiagramBreadcrumbModule} from "../diagram-breadcrumb/diagram-breadcrumb.module";
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {ApplicationForComparison, ApplicationGeneric} from "../../../../services/model/new-application.model";
import {CurrentTenantService} from "../../../../services/front/current-tenant.service";
import {NewApplicationService} from "../../../../services/back/new-application.service";
import {finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {filter} from "rxjs/operators";
import {ChipsModule} from "../../../global/chips/chips/chips.module";
import {animate, style, transition, trigger} from "@angular/animations";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import {PdfGenerator} from "../common/pdf/pdf-generator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {CostPipe} from 'src/app/pipes/number/cost.pipe';


@Component({
  selector: 'app-diagram-app-comparison',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule, DiagramBreadcrumbModule, DesignSystemModule, ChipsModule, MatCheckboxModule, CostPipe],
  templateUrl: './diagram-app-comparison.component.html',
  styleUrl: './diagram-app-comparison.component.scss',
	animations: [
		trigger(
			'appSelectIn',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(0%)' }),
						animate('300ms 0ms cubic-bezier(.47,1.64,.41,.8)',
							style({ transform: 'translateY(-1100%)'}))
					]
				),
			]
		),
	]
})
export class DiagramAppComparisonComponent implements OnInit, OnDestroy {

	protected readonly environment = environment;

	_initializing: boolean;
	_loading: boolean;
	_appALoading: boolean;
	_appBLoading: boolean;
	_exporting: boolean;
	_appASearching: boolean;
	_appBSearching: boolean;

	applications: ApplicationGeneric[] = [];
	tenantId: string;
	appA: ApplicationForComparison|undefined;
	appB: ApplicationForComparison|undefined;

	formGroup: FormGroup

	searchAppAControl: FormControl;
	appAFilteredList: ApplicationGeneric[] = [];
	searchAppBControl: FormControl;
	appBFilteredList: ApplicationGeneric[] = [];

	subscription: Subscription = new Subscription();

	constructor(
		private currentTenantService: CurrentTenantService,
		private applicationService: NewApplicationService,
		private translate: TranslateService,
	) {
	}

	ngOnInit() {
		this.createForm();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
	}

	initialize() {
		this.applications = [];
		this.setLoading(true)
			.pipe(
				switchMap(() => this.applicationService.getAllApplication(this.tenantId)),
				tap(applications => this.setApplications(applications)),
				finalize(() => this.setLoading(false)))
			.subscribe();
	}

	createForm() {
		this.formGroup = new FormGroup({
			[Form.appA]: new FormControl(undefined),
			[Form.appB]: new FormControl(undefined),
		});
		this.searchAppAControl = new FormControl('');
		this.subscription.add(this.searchAppAControl.valueChanges
			.subscribe(text => this.filterAppAList(text)));
		this.searchAppBControl = new FormControl('');
		this.subscription.add(this.searchAppBControl.valueChanges
			.subscribe(text => this.filterAppBList(text)));

		this.subscription.add(this.formGroup.get(Form.appA)!.valueChanges
			.pipe(
				filter(() => this.appAFormValue !== undefined && this.appAFormValue !== null),
				switchMap(() => this.selectApp(this.appAFormValue!.id, 'a'))
			)
			.subscribe());
		this.subscription.add(this.formGroup.get(Form.appB)!.valueChanges
			.pipe(
				filter(() => this.appBFormValue !== undefined && this.appBFormValue !== null),
				switchMap(() => this.selectApp(this.appBFormValue!.id, 'b'))
			)
			.subscribe());
	}

	selectApp(applicationId: string, appSide: 'a' | 'b'): Observable<{}> {
		if (appSide === 'a')
			this._appASearching = false;
		if (appSide === 'b')
			this._appBSearching = false;
		return this.setAppLoading(true, appSide)
			.pipe(
				switchMap(() => this.applicationService.getApplicationForComparison(this.tenantId, applicationId)),
				tap(application => this.setApplication(application, appSide)),
				tap(() => this.resetSearch(appSide)),
				switchMap(() => this.setAppLoading(false, appSide))
			);
	}

	setApplication(application: ApplicationForComparison, appSide: 'a' | 'b'): void {
		if (appSide === 'a') {
			this.appA = application;
		}
		if (appSide === 'b') {
			this.appB = application;
		}
	}

	resetSearch(appSide: 'a' | 'b') {
		if (appSide === 'a') {
			this.searchAppAControl.setValue('');
			this.appAFilteredList = this.applications;
		}
		if (appSide === 'b') {
			this.searchAppBControl.setValue('');
			this.appBFilteredList = this.applications;
		}

	}

	changeFormAppValue(app: ApplicationGeneric, appSide: 'a' | 'b') {
		if (appSide === 'a') {
			this.formGroup.get(Form.appA)!.setValue(app);
		}
		if (appSide === 'b') {
			this.formGroup.get(Form.appB)!.setValue(app);
		}
	}

	private filterAppAList(filter?: string): void {
		this.appAFilteredList = this.getFilteredApplicationList(filter);
	}

	private filterAppBList(filter?: string): void {
		this.appBFilteredList = this.getFilteredApplicationList(filter);
	}

	private getFilteredApplicationList(filter?: string): ApplicationGeneric[] {
		return this.applications
			.filter(app =>  (!filter || filter === '' || app.name.toLowerCase().includes(filter.toLowerCase())))
			.sort((a, b) => a.name.localeCompare(b.name))
	}

	setApplications(applications: ApplicationGeneric[]): Observable<{}> {
		this.applications = applications;
		this.appAFilteredList = applications;
		this.appBFilteredList = applications;
		return of({});
	}

	/*exportToPDF() {
		const title = this.translate.instant('page.diagram.appComparison.pdf.title');
		const subtitle = '';
		this._exporting = true;
		const dpi = Math.max(2, window.devicePixelRatio);
		const captureElement: HTMLElement | null = document.querySelector("#canvas");
		if (captureElement) {

			// get html tag and set overflow hidden
			const html: HTMLElement | null = document.querySelector('html');
			if (html) {
				html.style.overflow = 'hidden';
			}

			const cloneContainer = captureElement.cloneNode(true) as HTMLElement;

			cloneContainer.style.flexWrap = 'nowrap';

			// in clone container find .wrapper and set flex direction to row
			const wrapper: HTMLElement | null = cloneContainer.querySelector('.wrapper');
			if (wrapper) {
				wrapper.style.flexDirection = 'row';
			}

			document.body.appendChild(cloneContainer);

			// Temporarily disable flex-wrap
			html2canvas(cloneContainer, { width: 1920, height: 1080, windowWidth: 1920, windowHeight: 1080, useCORS: true}).then((canvas) => {
				// get html tag and set overflow auto
				if (html) {
					html.style.overflow = 'auto';
				}
				cloneContainer.remove();

				const currentWidth = 1920
				const currentHeight = 1080

				return new PdfGenerator(canvas, currentWidth * dpi, currentHeight * dpi).buildPdf(title, subtitle)
					.subscribe({
						next: pdf => this.savePdf(pdf),
						error: error => console.error(error),
						complete: () => {
							this._exporting = false
						}
					});
			}).catch((error) => {
				this._exporting = false;
				console.error(error);
			});
		} else {
			this._exporting = false;
		}
	}*/

	private savePdf(pdf: jsPDF): void {
		const titleDate: string = new Date().toISOString().split('T')[0];
		pdf.save('comparison-' + titleDate + '.pdf');
	}

	private setLoading(loading: boolean): Observable<{}> {
		this._loading = loading;
		return of({});
	}

	private setAppLoading(loading: boolean, appSide: 'a' | 'b'): Observable<{}> {
		if (appSide === 'a') {
			this._appALoading = loading;
		} else {
			this._appBLoading = loading;
		}
		return of({});

	}

	get accurateZoomLevel() {
		const screenWidth = screen.width;
		const windowInnerWidth = window.innerWidth;
		return (screenWidth / windowInnerWidth) * 100;
	}

	get appAFormValue(): ApplicationGeneric|undefined {
		return this.formGroup.get(Form.appA)!.value;
	}

	get appBFormValue(): ApplicationGeneric|undefined {
		return this.formGroup.get(Form.appB)!.value;
	}

	get appAFormControl(): FormControl {
		return this.formGroup.get(Form.appA)! as FormControl;
	}

	get appBFormControl(): FormControl {
		return this.formGroup.get(Form.appB)! as FormControl;
	}

	get currentYear(): number {
		return new Date().getFullYear();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe()
	}
}

enum Form {
	appA = 'appA',
	appB = 'appB'
}
