import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {distinctUntilChanged, finalize, mergeMap, Observable, of, Subscription, switchMap, takeWhile, tap} from "rxjs";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {filter, first, map} from "rxjs/operators";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {TenantExtensionSettings, TenantExtensionSettingsForm, TenantOverview, TenantService} from "src/app/services/tenant.service";
import {SnackbarModule} from "../../../global/snackbar/snackbar.module";
import {RightSliderService} from "src/app/services/front/right-slider.service";
import {HomePageDrawerComponent} from "../../home-page/home-page-drawer/home-page-drawer.component";
import {TipCarousel} from "../../home-page/home-page.component";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {CopyTextModule} from "../../../global/input/copy-text/copy-text.module";
import {ConfirmComponent, ConfirmModel} from "../../../global/dialog/confirm/confirm.component";
import {RoverService} from "src/app/services/rover.service";
import {MatDialog} from "@angular/material/dialog";
import {MatMenuModule} from "@angular/material/menu";
import {DeleteDialogComponent} from "../../../design-system/delete-dialog/delete-dialog.component";

@Component({
  selector: 'app-extension-settings',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgOptimizedImage, ReactiveFormsModule, SnackbarModule, DesignSystemModule, CopyTextModule, MatMenuModule],
  templateUrl: './extension-settings.component.html',
  styleUrl: './extension-settings.component.scss'
})
export class ExtensionSettingsComponent implements OnInit, OnDestroy {
	extensionSettings: TenantExtensionSettings;
	tenant: TenantOverview;
	isEditor: boolean;
	_initializing: boolean;
	_loading: boolean;

	uploadImage = false;
	uploading = false;

	extensionForm: FormGroup;
	frequenctTypeChoices: FrequencyTypeChoice[];
	updateExtensionOnBlur = new EventEmitter<Form>();

	formName = Form;

	subscription: Subscription = new Subscription();

	constructor(private translate: TranslateService,
				private snackBar: SnackbarService,
				private currentTenantService: CurrentTenantService,
				private tenantService: TenantService,
				private rightSliderService: RightSliderService,
				private roverService: RoverService,
				private dialog: MatDialog) {}

	ngOnInit() {
		this.buildForm();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(
				tap(tenant => this.tenant = tenant),
				tap(tenant => 	this.isEditor = tenant.configuration.role !== 'read_only'))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				mergeMap(() => this.tenantService.getTenantExtensionSettings(this.tenant.configuration.id)),
				tap(extensionSettings => this.extensionSettings = extensionSettings),
				tap(() => this.setDefaultData()),
				tap(() => this.fixMarkAsTouched()),
				finalize(() => this.switchLoading()))
			.subscribe());

	}

	buildForm(): void {
		this.frequenctTypeChoices = [
			{ id: FrequencyType.MONTHLY, name: this.translate.instant('global.monthly') },
			{ id: FrequencyType.QUARTERLY, name: this.translate.instant('global.quarterly') },
			{ id: FrequencyType.ANNUALLY, name: this.translate.instant('global.annually') },
		];

		this.extensionForm = new FormGroup({
			[Form.displayExtensionCheck]: new FormControl(true, []),
			[Form.supportUrlCheck]: new FormControl(false, []),
			[Form.supportUrl]: new FormControl(undefined, []),
			[Form.feedbackCheck]: new FormControl(true, []),
			[Form.feedback]: new FormControl(this.frequenctTypeChoices[0], [Validators.required]),
		});


		this.extensionForm.disable();
		this.subscription.add(this.extensionForm.get(Form.supportUrl)!.valueChanges
			.pipe(distinctUntilChanged())
			.subscribe(() => this.checkSupportUrlDisabled()));
		this.subscription.add(this.updateExtensionOnBlur
			.pipe(filter(() => !this.isExtensionFormValid()))
			.subscribe(() => this.setExtensionFormDefaultData(this.extensionSettings)));
		this.subscription.add(this.extensionForm.get(Form.feedback)!.valueChanges
			.subscribe(() => this.updateExtensionOnBlur.emit(Form.feedback)));
		this.updateExtensionOnBlur
			.pipe(
				filter(formName => this.isExtensionFormValid() && this.checkExtensionFormChanged(formName)),
				map(() => this.buildExtensionForm()),
				mergeMap(form => this.tenantService.updateTenantExtensionSettings(this.tenant.configuration.id, form)),
				tap(updateSucceed => this.displayUpdatedSucceed(updateSucceed)),
				mergeMap(() => this.tenantService.getTenantExtensionSettings(this.tenant.configuration.id)),
				tap(extensionSettings => this.extensionSettings = extensionSettings),
				takeWhile(() => !this.subscription.closed))
			.subscribe();
	}

	private setDefaultData(): void {
		this.setExtensionFormDefaultData(this.extensionSettings)
		this.checkSupportUrlDisabled();
	}

	private fixMarkAsTouched(): void {
		Object.values(Form)
			.map(formName => this.extensionForm.get(formName)!)
			.filter(control => !!control.validator)
			.forEach(control => control.valueChanges.pipe(first()).subscribe(() => control.markAsTouched()));

		this.feedbackFormControl.markAsDirty();
	}

	private setExtensionFormDefaultData(extensionSettings: TenantExtensionSettings): void {
		const frequency = this.frequenctTypeChoices.find(frequency => frequency.id === extensionSettings.satisfactionFrequency);
		this.extensionForm.get(Form.displayExtensionCheck)!.setValue(extensionSettings.displayRover);
		this.extensionForm.get(Form.supportUrl)!.setValue(extensionSettings.supportUrl);
		this.extensionForm.get(Form.supportUrlCheck)!.setValue(extensionSettings.supportUrlActivated);
		this.extensionForm.get(Form.feedbackCheck)!.setValue(extensionSettings.satisfactionActivated);
		if (frequency) {
			this.extensionForm.get(Form.feedback)!.setValue(frequency, {emitEvent: false});
		}
	}

	checkSupportUrlDisabled(): void {
		if (!this.isEditor) {
			this.extensionForm.disable();
		} else {
			this.extensionForm.enable();
			if (!this.extensionForm.get(Form.supportUrl)!.value || !this.extensionForm.get(Form.supportUrl)!.valid) {
				this.extensionForm.get(Form.supportUrlCheck)!.setValue(false);
				this.extensionForm.get(Form.supportUrlCheck)!.disable();
			} else {
				this.extensionForm.get(Form.supportUrlCheck)!.enable();
			}
		}
	}

	isExtensionFormValid(): boolean {
		return this.extensionForm.dirty && this.extensionForm.valid && this.extensionForm.enabled;
	}

	checkExtensionFormChanged(formName?: Form): boolean {
		return !formName
			?  Object.values(Form).map(formName => this.checkExtensionForm(formName)).reduce((a, b) => a || b)
			: this.checkExtensionForm(formName);
	}

	private checkExtensionForm(formName: Form): boolean {
		switch (formName) {
			case Form.displayExtensionCheck: return this.extensionForm.get(Form.displayExtensionCheck)!.value !== this.extensionSettings.displayRover;
			case Form.supportUrl: {
				if (this.extensionForm.get(Form.supportUrl)!.value === '') {
					return this.extensionSettings.supportUrl !== null
				}
				return this.extensionForm.get(Form.supportUrl)!.value !== this.extensionSettings.supportUrl;
			}
			case Form.supportUrlCheck: return this.extensionForm.get(Form.supportUrlCheck)!.value !== this.extensionSettings.supportUrlActivated;
			case Form.feedbackCheck: return this.extensionForm.get(Form.feedbackCheck)!.value !== this.extensionSettings.satisfactionActivated;
			case Form.feedback: return this.feedbackFormValue.id !== this.extensionSettings.satisfactionFrequency;
		}
	}

	private buildExtensionForm(): TenantExtensionSettingsForm {
		return {
			displayExtensionCheck: this.extensionForm.get(Form.displayExtensionCheck)!.value,
			supportUrl: this.extensionForm.get(Form.supportUrl)!.value,
			supportUrlCheck: this.extensionForm.get(Form.supportUrlCheck)!.value,
			feedbackCheck: this.extensionForm.get(Form.feedbackCheck)!.value,
			feedback: this.feedbackFormValue.id,
		};
	}

	displayUpdatedSucceed(updateSucceed: boolean): void {
		if (updateSucceed) {
			this.snackBar.show(this.translate.instant('page.application.detail.update.success'));
		} else {
			this.snackBar.show(this.translate.instant('page.application.detail.update.fail'), 'danger-snack');
		}
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	get supportUrlFormControl(): FormControl {
		return this.extensionForm.get(Form.supportUrl) as FormControl;
	}

	get feedbackCheckFormControl(): FormControl {
		return this.extensionForm.get(Form.feedbackCheck) as FormControl;
	}

	get supportUrlCheckFormControl(): FormControl {
		return this.extensionForm.get(Form.supportUrlCheck) as FormControl;
	}

	get displayExtensionCheckFormControl(): FormControl {
		return this.extensionForm.get(Form.displayExtensionCheck) as FormControl;
	}

	get feedbackFormControl(): FormControl {
		return this.extensionForm.get(Form.feedback) as FormControl;
	}

	get feedbackFormValue(): FrequencyTypeChoice {
		return this.extensionForm.get(Form.feedback)!.value;
	}

	selectFile() {
		if (!this.uploading) {
			const input: HTMLInputElement = document.createElement('input');
			input.type = 'file';
			input.accept = 'image/*';
			input.multiple = false;
			input.onchange = (event: Event) => {
				const target: HTMLInputElement = event.target as HTMLInputElement;
				if (target.files && target.files.length > 0) {
					Array.prototype.forEach.call(target.files, (file: File) => {
						this.uploadFile(file);
					});
				}
			};
			input.click();
		}
	}

	onDrop(event: any) {
		if (!this.uploading) {
			event.preventDefault();
			if (event.dataTransfer.files.length > 0) {
				Array.prototype.forEach.call(event.dataTransfer.files, (file) => {
					this.uploadFile(file);
				})
			}
		}
	}

	private uploadFile(file: File): void {
		if (
			file.type !== 'image/png'
			&& file.type !== 'image/jpeg'
			&& file.type !== 'image/jpg'
			&& file.type !== 'image/webp'
		) {
			this.snackBar.show(this.translate.instant('page.appDetails.settings.imageTypeNotSupported'), 'danger-snack');
		} else if (file.size / 1000000 > 10) {
			this.snackBar.show(this.translate.instant('page.appDetails.settings.imageTooBig'), 'danger-snack');
		}
		else {
			this.uploading = true;
			this.tenantService.uploadExtensionLogo(this.tenant.configuration.id, file)
				.subscribe({
					next: result => {
						if (result) this.snackBar.show(this.translate.instant('page.appDetails.settings.imageUploadSuccess'), 'success-snack');
						else this.snackBar.show(this.translate.instant('page.appDetails.settings.imageUploadError'), 'danger-snack');
						this.uploading = false;
						this.uploadImage = false;
						this.tenantService.getTenantExtensionSettings(this.tenant.configuration.id)
							.subscribe({
								next: extensionSettings => this.extensionSettings = extensionSettings,
								error: error => this.snackBar.show(this.translate.instant('page.appDetails.settings.imageUploadError'), 'danger-snack')
							});
					},
					error: error => {
						this.uploading = false;
						this.snackBar.show(this.translate.instant('page.appDetails.settings.imageUploadError'), 'danger-snack');
					}
				});
		}
	}

	deleteImage() {
		this.uploading = true;
		this.tenantService.deleteExtensionLogo(this.tenant.configuration.id)
			.subscribe({
				next: result => {
					if (result) this.snackBar.show(this.translate.instant('page.appDetails.settings.imageDeleteSuccess'), 'success-snack');
					else this.snackBar.show(this.translate.instant('page.appDetails.settings.imageDeleteError'), 'danger-snack');
					this.uploading = false;
					this.uploadImage = false;
					this.tenantService.getTenantExtensionSettings(this.tenant.configuration.id)
						.subscribe({
							next: extensionSettings => this.extensionSettings = extensionSettings,
							error: error => this.snackBar.show(this.translate.instant('page.appDetails.settings.imageDeleteError'), 'danger-snack')
						});
				},
				error: error => {
					this.uploading = false;
					this.snackBar.show(this.translate.instant('page.appDetails.settings.imageDeleteError'), 'danger-snack');
				}
			});

	}

	onRenewClick() {
		this.dialog.open(DeleteDialogComponent, {
			width: '475px',
			data: {
				icon: '/assets/illustrations/delete/token.svg',
				title: this.translate.instant('page.extension.delete.title'),
				subtitle: this.translate.instant('page.extension.delete.subtitle'),
				warningMessage: this.translate.instant('page.extension.delete.warningMessage'),
				deleteButton: this.translate.instant('page.extension.delete.deleteButton')
			}})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.roverService.renew(this.tenant.configuration.id))
			).subscribe((token) => this.extensionSettings.token = token);
	}

	openDrawerRoverTutorial() {
		const carousel: TipCarousel =
		{
			title: 'beginWithKabeen',
			cards: [
				{
					id: 'roverDeploy',
					picture: 'assets/illustrations/home-tips/rover.svg',
					video: undefined,
					sid: '',
					documentation: 'https://help.kabeen.io/fr/collections/5446894-extension-de-navigateur',
					listSize: 1
				}
			]
		}

		this.rightSliderService.openComponent(HomePageDrawerComponent, { carousel: carousel, tipIndex: 0 })
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

enum Form {
	displayExtensionCheck = 'displayExtensionCheck',
	supportUrlCheck = 'supportUrlCheck',
	supportUrl = 'supportUrl',
	feedbackCheck = 'feedbackCheck',
	feedback = 'feedback',
}

interface FrequencyTypeChoice {
	id: FrequencyType;
	name: string;
}

enum FrequencyType {
	MONTHLY = 'monthly',
	QUARTERLY = 'quarterly',
	ANNUALLY = 'annually'
}
