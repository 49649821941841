<div class="pl-l pb-l pr-l pt-l">
	<div style="height: 100%; width: 75vw" @in-out-animation *ngIf="!isAddingCustomApp">
		<!-- Title -->
		<div class="disp-f j-c-s a-i-c">
			<div>
				<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10" style="height: 45px; width: 45px;">
					<img src="assets/icons/package-plus.svg" class="svg-24">
				</div>
			</div>
			<div class="ml-s flex-column gap-XS">
				<div  class="font-L fw-700">{{ 'page.applicationCatalog.title' | translate }}</div>
				<div  class="font-M fw-300">{{ 'page.applicationCatalog.subtitle' | translate }}</div>
			</div>

			<!-- Confirm add button -->
            <app-text-button *ngIf="applicationBasket.length > 0" type="flat" style="margin-left: auto" [loading]="isSubmitting"
                             (click)="onSubmit()">{{ 'page.applicationCatalog.addButton' | translate }}</app-text-button>

			<!-- Close button -->
			<app-icon-button [img]="'assets/icons/close.svg'" type="stroked" background [panelClass]="'img-size-30 darken-icon'"
                             [class.ml-l]="applicationBasket.length > 0"  [class.ml-auto]="applicationBasket.length < 1"
                             (click)="onClose()"></app-icon-button>
		</div>

		<!-- Content -->
		<div class="disp-g-4 mt-l gap-XL" style="min-height:70vh; height:100%;grid-template-columns: 310px repeat(3, 1fr)">

			<!-- Search & category -->
			<div class="disp-f f-d-col" style="max-height: 70vh; overflow: auto; grid-template-columns: 1fr; grid-template-rows: 50px 50px 1fr 50px">
				<app-simple-text-input [clearEvent]="clearInput" (onValueChange)="onSearch({category: selectedCategoryId, searchQuery: $event, page: currentPage, queryAllCategory: lastSearchEvent?.queryAllCategory})" placeholder="{{ 'page.applicationCatalog.searchPlaceholder' | translate }}"></app-simple-text-input>

				<!-- Categories -->
				<div class="fw-700 mt-l">{{ 'page.applicationCatalog.categories' | translate }}</div>

				<div class="mb-l" style="height: 100%; overflow: auto" *ngIf="catalogCategoryStatistics">
					<div class="ml-s c-p mt-m disp-f j-c-sb" (click)="onClear()">
						<div [class.grey]="!selectAll" [class.fw-700]="selectAll">{{ 'page.applicationCatalog.all' | translate }}</div>
						<app-chips [color]="!selectAll ? 'grey' : 'secondary'" size="s">{{ catalogCategoryStatistics.totalApplications }}</app-chips>
					</div>
					<div class="ml-s c-p mt-m disp-f j-c-sb" *ngFor="let category of catalogCategoryStatistics.categories" (click)="onCategorySelected(category.category.id)">
						<div [class.grey]="category.category.id !== selectedCategoryId" [class.fw-700]="category.category.id === selectedCategoryId">{{ category.category.name }}</div>
						<app-chips [color]="category.category.id !== selectedCategoryId ? 'grey' : 'secondary'" size="s">{{ category.category.applicationCount }}</app-chips>
					</div>
				</div>

				<!-- Category loader -->
				<div *ngIf="isLoading" style="height: 100%;">
					<app-list-loader></app-list-loader>
				</div>

				<!-- Manually add app button -->
				<app-text-button class="full" type="stroked"
                                 (click)="displayCustomApp()">{{ 'page.applicationCatalog.createCustomApp' | translate }}</app-text-button>
			</div>

			<!-- App Carousels -->
			<div class="disp-f f-d-col span3 result-box"  style="padding-left: 3px; padding-right: 3px; max-height: 70vh; overflow: auto">

				<!-- Application basket -->
				<div @height-animation *ngIf="applicationBasket.length > 0" style="position: sticky;width:100%;z-index:100;">
					<div class="fw-700 pr-l pl-l mt-l accent" >{{ 'page.applicationCatalog.applicationToAdd' | translate: { count: applicationBasket.length} }}</div>
					<div class="carousel mt-s" style="min-height: 160px">
						<div class="card-container" style="width: 100%">
							<div *ngFor="let application of applicationBasket; let idx = index " class="card stacked-card ml-l hover-disabled">
								<div class="disp-f">
									<app-application-icon *ngIf="$any(application).logoURL" [height]="46" [width]="46" [logo]="{ url: $any(application).logoURL }"></app-application-icon>
									<app-application-icon *ngIf="$any(application).logo" [tileHeight]="'46px'" [tileWidth]="'46px'" [logo]="$any(application).logo"></app-application-icon>
									<div class="ml-s flex-column flex-space-evenly" style="height: 46px">
										<div class="font-M fw-700">{{ application.name }}</div>
										<div class="font-M fw-500">{{ getVendor(application) }}</div>
									</div>
									<div style="margin-left: auto">
										<div (click)="removeFromBasket(idx)" class="c-p flex a-i-c justify-center" style="width: 31px; border-radius: 20px; height: 31px;"  >
											<img src="assets/icons/trash-grey.svg">
										</div>
									</div>
								</div>
								<div class="font-S fw-500 mt-s">
									{{ application.description | slice:0:120 }}
									<span *ngIf="(application.description?.length||0) > 120">...</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- No ongoing research, display top apps -->
				<ng-container *ngIf="!isSearching && (!((lastSearchEvent?.searchQuery?.length || 0) > 0) && lastSearchEvent?.queryAllCategory)" >
					<ng-container *ngFor="let categoryStat of catalogCategoryStatistics?.categories">
						<div class="fw-700 pr-l pl-l mt-l">{{ categoryStat.category.name }}</div>
						<div class="carousel mt-s" style="min-height: 160px">
							<div class="card-container">
								<div *ngFor="let application of categoryStat.topApplications" class="card ml-l c-p" mat-ripple (click)="onAddToBasket(application)">
									<div class="disp-f">
										<app-application-icon *ngIf="application.logoURL" [width]="46" [height]="46" [logo]="{ url: application.logoURL }"></app-application-icon>
										<div class="ml-s flex-column flex-space-evenly" style="height: 46px">
											<div class="font-M fw-700">{{ application.name }}</div>
											<div class="font-M fw-500">{{ application.vendor }}</div>
										</div>
										<div class="ml-auto">
											<app-check *ngIf="shouldAnimateAppCard(application.id)"></app-check>
											<img *ngIf="!shouldAnimateAppCard(application.id)" src="assets/icons/add-to-basket.svg" class="filter-accent addToBasket">
										</div>
									</div>
									<div class="font-S fw-500 mt-s">
										{{ application.description | slice:0:120 }}
										<span *ngIf="(application.description?.length||0) > 120">...</span>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<!-- Searching... -->
				<ng-container *ngIf="isSearching || isLoading">
					<div *ngIf="lastSearchEvent?.searchQuery" class="fw-700 pr-l pl-l mt-l">{{ 'page.applicationCatalog.searchResults' | translate }}<span *ngIf="selectedCategoryId"> {{ 'page.applicationCatalog.searchWithCategory' | translate: {category: getCategoryName(selectedCategoryId)} }}</span></div>
					<div *ngIf="!lastSearchEvent?.searchQuery && lastSearchEvent?.category" class="fw-700 pr-l pl-l mt-l">{{ getCategoryName(selectedCategoryId) }}</div>

					<div class="disp-g ml-l mt-l" style="grid-template-columns: repeat(auto-fit, 330px); gap: 20px">
						<div *ngFor="let application of [1,2,3,4]" class="card" style="width: 330px; height: 155px;padding: 0; overflow: hidden">
							<app-dashboard-page-loader></app-dashboard-page-loader>
						</div>
					</div>
				</ng-container>

				<!-- Ongoing research -->
				<ng-container *ngIf="!isSearching && (lastSearchEvent && ((lastSearchEvent.searchQuery?.length || 0) > 0 || !!lastSearchEvent.category || !lastSearchEvent?.queryAllCategory))">
					<div *ngIf="lastSearchEvent?.searchQuery" class="fw-700 pr-l pl-l mt-l">{{ 'page.applicationCatalog.searchResults' | translate }}<span *ngIf="selectedCategoryId"> {{ 'page.applicationCatalog.searchWithCategory' | translate: {category: getCategoryName(selectedCategoryId)} }}</span></div>
					<div *ngIf="!lastSearchEvent?.searchQuery" class="fw-700 pr-l pl-l mt-l">{{ getCategoryName(selectedCategoryId) }}</div>

					<div *ngIf="searchResults.length > 0" class="disp-g ml-l mt-l" style="grid-template-columns: repeat(auto-fit, 330px); gap: 20px">
						<div *ngFor="let application of searchResults" class="card c-p" (click)="onAddToBasket(application)">
							<div class="disp-f">
								<app-application-icon *ngIf="application.logoURL" [height]="46" [width]="46" [logo]="{ url: application.logoURL }"></app-application-icon>
								<div class="ml-s flex-column flex-space-evenly" style="height: 46px">
									<div class="font-M fw-700">{{ application.name }}</div>
									<div class="font-M fw-500">{{ application.vendor }}</div>
								</div>
								<div class="ml-auto">
									<app-check *ngIf="shouldAnimateAppCard(application.id)"></app-check>
									<img *ngIf="!shouldAnimateAppCard(application.id)" src="assets/icons/add-to-basket.svg" class="filter-accent addToBasket">
								</div>
							</div>
							<div class="font-S fw-500 mt-s">
								{{ application.description | slice:0:120 }}
								<span *ngIf="(application.description?.length||0) > 120">...</span>
							</div>
						</div>
					</div>

					<!-- No results -->
					<div *ngIf="lastSearchEvent?.searchQuery && !searchResults.length" class="disp-f h-100 a-i-c j-c-c f-d-col">
						<img src="assets/icons/face-frown.svg" height="24" width="24" class="filter-grey">
						<div class="grey mt-l">{{ 'page.applicationCatalog.noResults' | translate }}</div>
						<app-text-button type="flat" class="mt-l"
                                         (click)="displayCustomApp(lastSearchEvent.searchQuery)">{{ 'page.applicationCatalog.manuallyAdd' | translate : { appName: lastSearchEvent.searchQuery} }}</app-text-button>
					</div>

					<app-text-button *ngIf="hasMoreApplication" type="dashed" class="mt-l mb-l"
                                     (click)="nextSearchPage()">{{ 'page.applicationCatalog.showMoreResults' | translate }}</app-text-button>
				</ng-container>

			</div>
		</div>
	</div>

	<div @width-shrink-animation *ngIf="isAddingCustomApp" >
		<div style="width: 400px;" class="disp-f f-d-col">
			<!-- Title -->
			<div class="disp-f j-c-s a-i-c w-100 mb-xl">
				<div>
					<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10" style="height: 45px; width: 45px;">
						<img src="assets/icons/package-plus.svg" class="svg-24">
					</div>
				</div>
				<div class="ml-s flex-column gap-XS">
					<div  class="font-L fw-700">{{ 'page.applicationCatalog.custom.title' | translate }}</div>
					<div  class="font-M fw-300">{{ 'page.applicationCatalog.custom.subtitle' | translate }}</div>
				</div>
			</div>
			<div class="flex-column gap-M">
				<div class="flex gap-L">
					<div class="flex-column gap-XS w-100">
						<div class="font-M fw-500 grey">{{ 'page.applicationCatalog.custom.appName' | translate }}*</div>
						<app-text-input [placeholder]="'page.applicationCatalog.custom.newApp' | translate"
										[input]="customAppNameControl"></app-text-input>
					</div>
					<div class="flex-column gap-XS w-100">
						<div class="font-M fw-500 grey">{{ 'page.applicationCatalog.custom.appType' | translate }}*</div>
						<app-multi-select class="w-100" [inputControl]="customAppTypeControl">
							<app-select-trigger appearance="field">{{customAppTypeControl.value?.name || '-'}}</app-select-trigger>
							<app-select-option *ngFor="let type of applicationTypeDropdown" [value]="type">{{type.name}}</app-select-option>
						</app-multi-select>
					</div>
				</div>
				<div class="flex-column gap-XS w-100">
					<div class="fw-500 flex-align-center">
						<div class="font-M fw-500 grey">{{ 'page.appDetails.accessUrl' | translate }}</div>
						<div class="disp-f a-i-c j-c-c" style="height: 25px; width: 25px;"
							 [tooltip]="'page.applicationCatalog.custom.tooltip' | translate" tooltipPosition="right">
							<img src="assets/icons/help-2.svg" width="14" height="14" alt=""/>
						</div>
					</div>
					<app-text-input [placeholder]="'https://www.new-app.com'"
									[input]="customAppUrlControl"></app-text-input>
					<ul @validators-appear *ngIf="customAppUrlControl.errors" class="errors-list">
						<li *ngIf="customAppUrlControl.errors?.['prefix']">{{ 'page.applicationCatalog.custom.error.1' | translate }}</li>
						<li *ngIf="customAppUrlControl.errors?.['suffixDomain']">{{ 'page.applicationCatalog.custom.error.2' | translate }}</li>
						<li *ngIf="customAppUrlControl.errors?.['suffixPort']">{{ 'page.applicationCatalog.custom.error.3' | translate }}</li>
						<li *ngIf="customAppUrlControl.errors?.['url']">{{ 'page.applicationCatalog.custom.error.4' | translate }}</li>
					</ul>
				</div>
				<div class="flex-column gap-XS">
					<div class="font-M fw-500 grey">{{ 'page.applicationCatalog.custom.appCategory' | translate }}</div>
					<app-multi-select class="w-100" [inputControl]="customAppCategoryControl">
						<app-select-trigger appearance="field">{{customAppCategoryControl.value?.name || '-'}}</app-select-trigger>
						<app-select-search [searchControl]="searchCategoryControl"></app-select-search>
						<app-select-insert [saving]="_savingNewCategory" (create)="createCategory($event)"></app-select-insert>
						<app-select-option [value]="null">{{'-'}}</app-select-option>
						<app-select-option *ngFor="let category of filteredCategoryList" [value]="category">{{category.name}}</app-select-option>
					</app-multi-select>
				</div>

				<div class="flex-column gap-XS">
					<div class="font-M fw-500 grey">{{ 'page.applicationCatalog.custom.appDescription' | translate }}</div>
					<app-text-area [placeholder]="'page.applicationCatalog.custom.description' | translate"
								   [rows]="5"
								   [input]="customAppDescriptionControl"></app-text-area>
				</div>
			</div>
            <div class="flex gap-M mt-l">
                <app-text-button type="stroked" class="w-50"
                                 (click)="hideCustomApp()">
                    {{'page.applicationCatalog.custom.cancel'|translate}}
                </app-text-button>
                <app-text-button type="flat" class="w-50" [disabled]="!customAppFormGroup.valid || _savingNewCategory"
                                 (click)="onAddCustomApp()">
                    {{'page.applicationCatalog.custom.add'|translate}}
                </app-text-button>
            </div>
		</div>
	</div>
</div>
