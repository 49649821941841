import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { CommonModule } from "@angular/common";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import { DeckContentLoaderModule } from "../../global/title-content-loader/deck-content-loader.module";

@Component({
	selector: 'app-table-component',
	templateUrl: './table.component.html',
	standalone: true,
	imports: [CommonModule, TranslateModule, DeckContentLoaderModule],
	styleUrls: ['./table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent<T> implements OnChanges {
	constructor(private translate: TranslateService) {}

	@Input() columnDefinitions: ColumnDefinition<T>[];
	@Input() data: T[];
	@Input() loading: boolean;
	@Input() type: 'drawer' | 'widget' = 'widget';
	@Input() height: string = 'auto';
	@Input() noDataMessage: string;
	@Output() sorted = new EventEmitter<T[]>();
	@Output() rowClick = new EventEmitter<T>();

	sortColumn: NestedKeyOf<T> | null = null;
	sortDirection: 'asc' | 'desc' = 'asc';
	sortedData: T[];
	columnWidth: number;
	isEmpty: boolean;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.data && this.data) {
			this.sortedData = [...this.data];
			this.isEmpty = this.data.length === 0;
		}

		if (changes.columnDefinitions && this.columnDefinitions) {
			this.calculateColumnWidth();
		}

		if (!this.noDataMessage) {
			if (this.type === 'drawer') {
				this.noDataMessage = this.translate.instant('global.table.noDataDrawer');
			}
			if (this.type === 'widget') {
				this.noDataMessage = this.translate.instant('global.table.noDataWidget');
			}
		}
	}

	sortBy(column: NestedKeyOf<T>): void {
		if (this.type === 'widget') return;
		this.sortDirection = this.sortColumn === column && this.sortDirection === 'asc' ? 'desc' : 'asc';
		this.sortColumn = column;

		const columnDefinition = this.columnDefinitions.find((c) => c.name === column);
		const sortFunction = columnDefinition?.sortFunction || this.defaultSortFunction(column);

		this.sortedData = [...this.data].sort((a, b) => {
			const result = sortFunction(a, b);
			return this.sortDirection === 'asc' ? result : -result;
		});

		this.sorted.emit(this.sortedData);
	}

	private defaultSortFunction(column: NestedKeyOf<T>): SortFunction<T> {
		return (a: T, b: T) => {
			const valueA = this.getNestedValue(a, column);
			const valueB = this.getNestedValue(b, column);
			if (valueA < valueB) return -1;
			if (valueA > valueB) return 1;
			return 0;
		};
	}

	getNestedValue(obj: T, path: string): any {
		return path.split('.').reduce((o, key) => o && o[key] !== undefined ? o[key] : null, obj as any);
	}

	private calculateColumnWidth(): void {
		this.columnWidth = this.columnDefinitions.length > 0 ? 100 / this.columnDefinitions.length : 0;
	}

	getHeaderColumnClass(isLast: boolean, isFirst: boolean, column: NestedKeyOf<T>): string[] {
		return [
			isLast ? 'align-right' : isFirst ? 'align-left' : 'align-center',
			this.sortColumn === column ? 'sort-active' : '',
			this.type === 'drawer' ? 'c-p' : '',
			this.type === 'drawer' ? 'prevent-select' : '',
			this.type === 'drawer' ? 'greyed-hover' : '',
			this.type === 'drawer' ? 'border-light-grey-bottom' : '',
			this.type === 'drawer' ? 'sort-column' : ''
		].filter(Boolean);
	}

	getColumnClass(isLast: boolean): string {
		return isLast ? 'align-right' : 'align-left';
	}
}

export type SortFunction<T> = (a: T, b: T) => number;
export type ColumnTemplate<T> = TemplateRef<{ $implicit: T }>;

type NestedKeyOf<T, K = keyof T> = K extends keyof T & (string | number)
	? `${K}` | (T[K] extends object ? `${K}.${NestedKeyOf<T[K]>}` : never)
	: never

export interface ColumnDefinition<T> {
	name: NestedKeyOf<T>;
	"label": string;
	template?: ColumnTemplate<T>;
	sortFunction?: SortFunction<T>;
	width?: number;
}
