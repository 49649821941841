import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ApplicationUsageDetail, Team, UsageService} from "src/app/services/usage.service";
import {finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {MatDialog} from '@angular/material/dialog';
import {AddTeamDialogComponent, AddTeamDialogData} from "./add-team-dialog/add-team-dialog.component";
import {ApplicationUrlService} from "src/app/services/front/application-url.service";
import {ApplicationDetailData, ApplicationDetailService} from "src/app/services/front/application-detail.service";
import {AppSettingsMode} from "../application-settings/application-settings.component";
import {ApplicationInstance} from 'src/app/services/model/new-application.model';
import {TendencyWidgetContent} from 'src/app/modules/global/application-mini-widget/application-mini-widget.component';

@Component({
	selector: 'app-usage-tab',
	templateUrl: './usage-tab.component.html',
	styleUrls: ['./usage-tab.component.scss']
})
export class UsageTabComponent implements OnInit, OnDestroy {

	tenantId: string;
	application: ApplicationInstance;

	_initializing: boolean;
	_loading: boolean;
	initialized: boolean = false;

	applicationUsage: ApplicationUsageDetail;
	applicationUsersWidget: TendencyWidgetContent;
	satisfactionWidget: TendencyWidgetContent;
	activityTimeWidget: TendencyWidgetContent;
	displayAll: boolean = false;

	firstTimeSeeingUsage: boolean = false;

	subscriptions: Subscription = new Subscription();

	@Input()
	displayAutodiscoverTeamsNotificationTooltip: boolean = false;

	@Output()
	autodiscoveredTeamsChanged: EventEmitter<number> = new EventEmitter<number>();

	@Output()
	autodiscoveredTeamsSeen: EventEmitter<void> = new EventEmitter<void>();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationUrlService: ApplicationUrlService,
							private usageService: UsageService,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscriptions.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));

		this.subscriptions.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => this.usageService.getApplicationUsage(this.tenantId, this.application.applicationId)
					.pipe(
						tap(usage => this.setApplicationUsage(usage)),
						tap(() => this.autodiscoveredTeamsSeen.emit()),
						tap(() => this.autodiscoveredTeamsChanged.emit(this.autodiscoveredTeamsCount)),
						tap(() => this.checkIfFirstTimeSeeingUsage())
					)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private checkIfFirstTimeSeeingUsage(): void {
		const firstTime = localStorage.getItem('firstTimeSeeingUsage');
		if (firstTime === null) {
			this.firstTimeSeeingUsage = true;
			localStorage.setItem('firstTimeSeeingUsage', 'false');
		}
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.application = data.instance;
	}

	private setApplicationUsage(usage: ApplicationUsageDetail): void {
		if (usage && usage.teams) {
			usage.teams = usage.teams.sort(this.sortTeam);
		}
		this.applicationUsage = usage;
		this.applicationUsersWidget = {
			value: this.application.usageActivated
				? usage.usage.value
				: undefined,
			day: usage.usage.queryType
		};
		this.activityTimeWidget = {
			value: this.application.usageActivated
				? usage.activityTimeAverage.value
				: undefined,
			day: usage.activityTimeAverage.queryType
		};
		this.satisfactionWidget = {
			value: this.application.usageActivated
				? usage.satisfaction.rating.value
				: undefined,
			day: usage.satisfaction.rating.queryType
		};
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private sortTeam = (team1: Team, team2: Team): number => {
		// Sort by autodiscovered if autodiscoverd, first
		if (team1.autodiscovered && !team2.autodiscovered) return -1;
		if (!team1.autodiscovered && team2.autodiscovered) return 1;

		// Sort by user count
		if ((team2.userCount || 0) > (team1.userCount || 0)) return 1;
		if ((team2.userCount || 0) < (team1.userCount || 0)) return -1;

		// Sort by structure
		if (team1.structureName < team2.structureName) return -1;
		if (team1.structureName > team2.structureName) return 1;

		// Sort by business unit
		if (team1.businessUnitName < team2.businessUnitName) return -1;
		if (team1.businessUnitName > team2.businessUnitName) return 1;

		return 0;
	}

	onChangeTeams(): void {
		const data: AddTeamDialogData = {
			applicationId: this.application.applicationId,
			selectedTeams: this.applicationUsage.teams.filter(team => !team.autodiscovered),
			autodiscoveredTeams: this.applicationUsage.teams.filter(team => team.autodiscovered)
		};
		this.dialog.open(AddTeamDialogComponent, { position: { right: '162.5px' }, width: '475px', data: data })
			.afterClosed()
			.subscribe(() => this.initialize());
	}

	navigateToUsageParameter(): void {
		if (!this.application.usageActivated) {
			this.applicationUrlService.navigateInAndOutOfParameter(AppSettingsMode.USAGE_URL);
		}
	}

	get autodiscoveredTeamsCount(): number {
		return this.applicationUsage ? this.applicationUsage.teams.filter(team => team.autodiscovered).length : 0;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
