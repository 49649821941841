<app-snackbar></app-snackbar>
<div class="pl-m pr-m pt-m pb-m">
	<div>
		<div class="disp-f a-i-c mr-s">
            <span class="font-MM fw-700">{{ 'page.extension.extensionSettings' | translate }}</span>
		</div>
	</div>
	<div class="p-relative">
		<div class="text-in-illustration font-L fw-300 white">
			<span class="fw-700">{{ 'page.extension.ext.0' | translate }}</span> {{ 'page.extension.ext.1' | translate }} <span class="fw-700">{{ 'page.extension.ext.2' | translate }}</span> {{ 'page.extension.ext.3' | translate }}<br>{{ 'page.extension.ext.4' | translate }}<br>{{ 'page.extension.ext.5' | translate }}<br><span class="fw-700">{{ 'page.extension.ext.6' | translate }}</span>
		</div>
		<div class="button-in-illustration disp-f a-i-c c-p prevent-select pad-0-L" (click)="openDrawerRoverTutorial()">
			<img class="filter-white" ngSrc="assets/icons/alert-circle.svg" alt="" height="25" width="25">
			<span class="ml-xs white font-M">
				{{ 'page.extension.howToDeploy' | translate }}
			</span>
		</div>
		<div class="pb-xs pt-xs pl-xs pr-xs disp-f j-c-fe a-i-c mt-m extension-settings-illustration p-relative overflow-hidden">
			<img src="/assets/illustrations/extension-settings-bg.svg" height="300" alt="Extension settings illustration" style="margin-right: 30px; margin-top: 4px;">
		</div>
	</div>
	<div class="hr-document mt-m mb-xl ml-xs mr-xs"></div>
	<div class="disp-f j-c-sb pl-s pr-s p-relative">
		<div class="disp-f f-d-col gap-XS">
			<span class="fw-700">{{ 'page.extension.logo' | translate }}</span>
			<span>
				{{ 'page.extension.logo.0' | translate }}<br>{{ 'page.extension.logo.1' | translate }}
			</span>
		</div>
		<div *ngIf="!extensionSettings || !extensionSettings.publicLogoUrl" (click)="selectFile()" (drop)="onDrop($event)" (dragover)="$event.preventDefault()" (dragleave)="$event.preventDefault()" class="add-logo-btn c-p prevent-select disp-f a-i-c j-c-c">
			<img class="filter-grey" ngSrc="assets/icons/add.svg" height="15" width="15" alt="">
		</div>
		<div *ngIf="extensionSettings && extensionSettings.publicLogoUrl" class="logo-present prevent-select disp-f a-i-c j-c-c">
			<img [src]="extensionSettings.publicLogoUrl" height="30" width="30" alt="Logo de l'organisation" class="logo-organisation">
		</div>
		<div (click)="deleteImage()" *ngIf="extensionSettings && extensionSettings.publicLogoUrl && isEditor" class="delete-image prevent-select c-p">
			<img class="filter-grey" ngSrc="assets/icons/trash.svg" height="20" width="15" alt="">
		</div>
	</div>
	<div class="hr-document mt-l mb-l ml-xs mr-xs"></div>
	<div class="disp-f j-c-sb pl-s pr-s">
		<div class="disp-f f-d-col gap-XS">
			<div class="fw-700 flex-align-center">
				<span>{{ 'page.extension.code.0' | translate }}</span>
			</div>
			<span>{{ 'page.extension.code.1' | translate }}</span>
		</div>
	</div>
	<div *ngIf="extensionSettings && extensionSettings.token" class="pl-s pr-s disp-f a-i-c mt-s gap-S" style="width: calc(100% - 20px)">
		<app-copy-text style="flex-grow: 1" [text]="extensionSettings.token|| ''"></app-copy-text>
		<button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="selectMenu" [disabled]="_initializing || _loading" class="renew-button flex-center c-p" [ngClass]="{ 'renew-button-active': menuTrigger.menuOpen }">
			<img src="assets/icons/dots-vertical.svg" height="18" alt=""/>
		</button>
		<mat-menu #selectMenu class="mat-menu-custom"
				  xPosition="before" yPosition="below">
			<div (click)="onRenewClick()" class="flex-align-center gap-M greyed-hover b-radius-6 c-p" style="padding: 6px;">
				<img src="assets/icons/refresh.svg" width="18" height="18" alt="" class="filter-danger"/>
				<div class="font-M fw-500 danger">{{'page.extension.renewOption'|translate}}</div>
			</div>
		</mat-menu>
	</div>
	<div class="hr-document mt-l mb-l ml-xs mr-xs"></div>
	<div class="disp-f j-c-sb pl-s pr-s">
		<div class="disp-f f-d-col gap-XS">
			<div class="fw-700 flex-align-center">
				<span>{{ 'page.extension.displayExtension' | translate }}</span>
                <div class="disp-f a-i-c j-c-c" style="height: 25px; width: 25px;"
                     [tooltip]="'page.extension.formInfo.0'|translate" tooltipPosition="right">
                    <img src="assets/icons/help-2.svg" width="14" height="14" alt=""/>
                </div>
			</div>
			<span>{{ 'page.extension.display.0' | translate }}</span>
		</div>
		<div>
			<app-toggle-button
				[toggleInput]="displayExtensionCheckFormControl"
				(change)="updateExtensionOnBlur.emit(formName.displayExtensionCheck)">
			</app-toggle-button>
		</div>
	</div>
	<div class="hr-document mt-l mb-l ml-xs mr-xs"></div>
	<div class="disp-f j-c-sb pl-s pr-s">
		<div class="disp-f f-d-col gap-XS">
			<div class="fw-700 flex-align-center">
				<span>{{ 'page.extension.supportRequest' | translate }}</span>
                <div class="disp-f a-i-c j-c-c" style="height: 25px; width: 25px;"
                     [tooltip]="'page.extension.formInfo.1'|translate" tooltipPosition="right">
                    <img src="assets/icons/help-2.svg" width="14" height="14" alt=""/>
                </div>
			</div>
			<span>{{ 'page.extension.support.0' | translate }}</span>
		</div>
		<div>
			<app-toggle-button
				[toggleInput]="supportUrlCheckFormControl"
				(change)="updateExtensionOnBlur.emit(formName.supportUrlCheck)">
			</app-toggle-button>
		</div>
	</div>
	<div class="pl-s pr-s disp-f a-i-c mt-s" style="width: calc(100% + 10px)">
		<div class="text-input-prefix disp-f a-i-c j-c-c">
			https://
		</div>
		<app-text-input
			panelClass="b-radius-right-6"
			style="width: calc(100% - 90px)"
			[input]="supportUrlFormControl"
			[placeholder]="'page.appDetails.domainPlaceholder'|translate"
			(blur)="updateExtensionOnBlur.emit(formName.supportUrl)"
			(keyup.enter)="updateExtensionOnBlur.emit(formName.supportUrl)">
		</app-text-input>
	</div>
	<div class="hr-document mt-l mb-l ml-xs mr-xs"></div>
	<div class="disp-f j-c-sb pl-s pr-s">
		<div class="disp-f f-d-col gap-XS">
			<div class="fw-700 flex-align-center">
				<span>{{ 'page.extension.userFeedback' | translate }}</span>
                <div class="disp-f a-i-c j-c-c" style="height: 25px; width: 25px;"
                     [tooltip]="'page.extension.formInfo.2'|translate" tooltipPosition="right">
                    <img src="assets/icons/help-2.svg" width="14" height="14" alt=""/>
                </div>
			</div>
			<span>{{ 'page.extension.user.0' | translate }}</span>
		</div>
		<div>
			<app-toggle-button
				[toggleInput]="feedbackCheckFormControl"
				(change)="updateExtensionOnBlur.emit(formName.feedbackCheck)"></app-toggle-button>
		</div>
	</div>
	<div class="pl-s pr-s disp-f a-i-c mt-s" style="width: calc(100% - 20px)">
		<app-multi-select class="w-100" [inputControl]="feedbackFormControl">
			<app-select-trigger appearance="field">
				<span>{{ feedbackFormValue.name }}</span>
			</app-select-trigger>
			<app-select-option *ngFor="let frequency of frequenctTypeChoices" [value]="frequency">
				<span>{{ frequency.name }}</span>
			</app-select-option>
		</app-multi-select>
	</div>
</div>
