import {Logo, PerformanceRating} from 'src/app/services/application-instance.service';
import {Compliance, CriticalityLevel} from 'src/app/services/tenant.service';
import {Category} from 'src/app/services/model/application-category.model';
import {TenantAccount} from 'src/app/services/model/account.model';
import {ContractInfo, Cost, Usage} from 'src/app/services/model/application-contract.model';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {Satisfaction} from 'src/app/services/usage.service';
import {Technology} from 'src/app/services/model/application-technology.model';
import {ServerOverview} from 'src/app/services/model/resource.model';

export interface ApplicationInstance {
  applicationId: string;
  tenantId: string;
  catalog: ApplicationCatalog|null;
  name: string;
  logo: string;
  memo: string|null;
  description: string|null;
  hostingType: HostingType;
  criticality: CriticalityLevel|null;
  accessUrl: string|null;
  usageDomain: string|null;
  usagePath: string|null;
  usageActivated: boolean;
  supportPhone: string|null;
  supportEmail: string|null;
  supportUrl: string|null;
  lastUpdate: Date;
  urlModalDismissed: boolean;
}

export interface ApplicationGeneric {
	id: string;
	name: string;
	logo: string;
	description?: string|null;
	criticality?: CriticalityLevel|null;
}

export interface ApplicationElasticTableData {
  applicationId: string;
  usage: Usage;
  health: Health;
}

export interface ApplicationHealth {
  application: ApplicationGeneric;
  health: Health;
}

export interface Health {
  percent: number|null;
  status: HealthStatus;
  queryType: QueryRangeType;
}

export enum HealthStatus {
  GOOD = 'good',
  NEEDS_IMPROVEMENT = 'needs_improvement',
  POOR = 'poor',
  UNKNOWN = 'unknown' // TODO @TAN manage orElse(100.0) through 'unknown' status
}

export interface ApplicationTableData {
  instance: ApplicationInstance;
  category: Category|null;
  cost: Cost;
}

export interface ApplicationTableFilterForm {
  name?: string|null;
  category?: string[];
  criticality?: CriticalityLevel[];
  organization?: string[];
  responsible?: string[];
  hostingType?: HostingType[];
  status?: ApplicationStatus[];
  tag?: string[];
  sortDirection?: string;
  sortColumn?: string;
  limit?: number;
  offset?: number;
}

export enum ApplicationSortColumn {
  NAME = 'name',
  HEALTH = 'health',
  CATEGORY = 'category',
  CRITICALITY = 'criticality',
  USAGE = 'usage',
  COST = 'cost',
}

export interface ApplicationCatalog {
  catalogId: string;
  categoryId: string|null;
  name: string;
  logo: string;
  type: HostingType|null;
  applicationUrl: string|null;
  vendor: string;
  vendorUrl: string;
  description: string;
  supportPhone: string|null;
  supportEmail: string|null;
  supportUrl: string|null;
  reviewScore: number|null;
}

export enum HostingType {
  SAAS = 'saas',
  HOSTED = 'hosted',
  HOMEMADE = 'homemade'
}

export interface ApplicationOverview {
  instance: ApplicationInstance;
  category: Category|null;
  responsible: ApplicationResponsible|null;
  status: ApplicationStatus|null;
}

export interface ApplicationResponsible {
  user: TenantAccount;
  role: ResponsibleRole;
}

export enum ResponsibleRole {
  BUSINESS = 'business',
  TECHNICAL = 'technical'
}

export interface ApplicationCreation {
  application: ApplicationCreationForm;
}

export interface ApplicationCreationForm {
  catalogId?: string;
  categoryId?: string;
  name?: string;
  description?: string;
  hostingType?: HostingType;
  accessUrl?: string;
  iconUrl?: string|null;
  tileBgColor?: string|null;
  tileFontColor?: string|null;
	autoDiscoverId?: string|null;
}

export interface ApplicationSettingGeneralForm {
  name: string;
  description?: string;
  accessUrl?: string;
  supportPhone?: string;
  supportEmail?: string;
  supportUrl?: string;
}

export interface ApplicationSettingUsageForm {
  usageDomain: string|null;
  usagePath: string|null;
  usageActivated: boolean;
}

export interface ApplicationCriticalityForm {
  criticality: CriticalityLevel|null;
}

export interface ApplicationResponsibleListForm {
  responsibleUsers: ApplicationResponsibleForm[];
}

export interface ApplicationResponsibleForm {
  accountId: string;
  role?: ResponsibleRole;
}

export interface ApplicationCategoryApplicationListForm {
  categories: ApplicationCategoryApplicationForm[];
}

export interface ApplicationCategoryApplicationForm {
  categoryId: string;
}

export interface ApplicationTypeForm {
  type: HostingType;
}

export interface ApplicationOrganizationListForm {
  organizations: ApplicationOrganizationForm[];
}

export interface ApplicationOrganizationForm {
  organizationId: string;
}

export interface ApplicationTagListForm {
  tags: ApplicationTagForm[]
}

export interface ApplicationTagForm {
  tagId: string;
}

export interface ApplicationForComparison {
	instance: ApplicationInstance;
	category: Category|null;
	health: Health;
	performance: PerformanceRating|null;
	usage: Usage;
	satisfaction: Satisfaction;
	cost: Cost;
	complexity: Compliance;
}

export enum ApplicationStatus {
  PHASE_IN = 'phase_in',
  DEPLOYED = 'deployed',
  PHASE_OUT = 'phase_out',
  RETIRED = 'retired'
}

export interface ApplicationExport {
  instance: ApplicationInstance;
  category: Category|null;
  health: Health;
  usage: Usage;
  cost: Cost;
  responsible: ApplicationResponsible|null;
  servers: ServerOverview[];
  technologies: Technology[];
}

export interface ApplicationOptimization {
  application: ApplicationGeneric;
  archived: boolean;
  contracts: ContractInfo[];
  usage: Usage;
  potentialEarnings: Cost;
}

export interface ApplicationOptimizationStatistic {
  usagePercent: number;
  potentialEarnings: Cost;
}

export interface ApplicationOptimizationForm {
  archived: boolean;
}
