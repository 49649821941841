<div class="h-100">
	@if (type === 'widget') {
		<div class="flex-align-center flex-space-between pad-bottom-M pad-M"
			 [class.c-p]="!_loading && !_initializing && applications.length > 0"
			 (click)="openDrawer()">
			<span class="font-MM fw-700">{{ 'page.financeDashboard.mostExpensiveApps'|translate }}</span>
			<img *ngIf="!_loading && !_initializing && applications.length > 0" src="assets/icons/arrow-right.svg"
				 height="12" alt="">
		</div>
    }
	<app-table-component
		[loading]="_initializing || _loading"
		[columnDefinitions]="columnDefinitions"
		[data]="filteredApplications"
		[height]="'calc(100% - 45.59px)'"
		[type]="type"
		(sorted)="onSorted($event)"
		(rowClick)="onRowClick($event)">
		<!-- Custom templates -->
		<ng-template #applicationTemplate let-row>
			<app-display-application [application]="row.application"></app-display-application>
		</ng-template>
		<ng-template #criticalityTemplate let-row>
			<div class="flex-center">
				<app-chips-criticality [criticality]="row.application.criticality"></app-chips-criticality>
			</div>
		</ng-template>
		<ng-template #usageTemplate let-row>
			<div class="w-100 flex-center">
				<app-chips-count [value]="row.usage.value" type="user" bold></app-chips-count>
			</div>
		</ng-template>
		<ng-template #annualCostTemplate let-row>
			<div class="flex-align-center flex-justify-end">
				<span class="font-M fw-700 text-overflow-ellipsis">{{row.cost.value|cost}}</span>
			</div>
		</ng-template>
	</app-table-component>
</div>
